import React from 'react'
import Header from '../component/Header';

function Home() {
  return (
   <>
    <Header/>
   </>
  )
}

export default Home
